import { InvoiceStatusType } from "@/constants/InvoiceCode";
import { InvoiceModel } from "@/models/InvoiceModel";
import { FinanceServiceClient } from "@/serviceClients/FinanceServiceClient";
import { PaginationFinance } from "@/serviceClients/responses/KokattoFinanceInvoiceResponse";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  ActiveInvoiceState,
  ActiveInvoiceStatus,
  AsyncState,
  State,
} from "./types";

const DEFAULT_ASYNC_STATE: AsyncState = {
  loading: false,
  data: null,
  error: null,
};

const state: State = {
  activeInvoice: { ...DEFAULT_ASYNC_STATE, lastUpdatedAt: 0 },
};

const getters: GetterTree<State, any> = {
  getActiveInvoice: (state: State): ActiveInvoiceState => state.activeInvoice,
};

const mutations: MutationTree<State> = {
  successGetActiveInvoice(state: State, invoice: ActiveInvoiceStatus) {
    state.activeInvoice = {
      ...DEFAULT_ASYNC_STATE,
      data: invoice,
      lastUpdatedAt: Date.now(),
    };
  },
  resetToDefaultState(state: State) {
    state.activeInvoice = {
      ...DEFAULT_ASYNC_STATE,
      lastUpdatedAt: 0,
    };
  },
};

const actions: ActionTree<State, any> = {
  async getActiveInvoices(
    { commit, rootGetters, state },
    isReset = false
  ): Promise<void> {
    try {
      const diff = Date.now() - state.activeInvoice.lastUpdatedAt;

      // if isReset true than always fetch to BE
      if (!isReset) {
        if (diff < 15 * 60 * 1000) {
          return;
        }
      }

      const serviceClient = new FinanceServiceClient({
        token: rootGetters.getKokattoTokenAccess,
      });

      const response: PaginationFinance<InvoiceModel> =
        await serviceClient.getInvoiceList(1, 10, "active");

      const invoices = [...response.items];

      const activeInvoiceStatus: ActiveInvoiceStatus = {
        overdueInvoices: [],
        pendingConfirmInvoices: [],
        pendingPaymentInvoices: [],
      };

      // change array to mapped array status
      for (const invoice of invoices) {
        if (invoice.status === InvoiceStatusType.OVERDUE) {
          activeInvoiceStatus.overdueInvoices.push(invoice);
        } else if (invoice.status === InvoiceStatusType.PENDING_CONFIRMATION) {
          activeInvoiceStatus.pendingConfirmInvoices.push(invoice);
        } else if (invoice.status === InvoiceStatusType.WAITING_PAYMENT) {
          activeInvoiceStatus.pendingPaymentInvoices.push(invoice);
        }
      }

      commit("successGetActiveInvoice", activeInvoiceStatus);
    } catch (error) {
      console.error(
        "MAM [ERROR] Something went wrong in getActiveInvoices()",
        error
      );
      // commit("errorGetMacrosList", error);
      throw error;
    }
  },
  resetActiveInvoices({ commit }): void {
    commit("resetToDefaultState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
