const id = {
  menuAnalytics: "Analitik",
  menuCustomDashboard: "Custom Dashboard",
  customDashboardAccessGroupNth: "Custom Dashboard {0}",
  customDashboardAutoRefreshLabel: "Refresh otomatis",
  customDashboardRefreshRateinMinutes: "{0} menit",
  customDashboardRefreshRateOff: "Mati",
  customDashboardEmptyDashboardLabel: "Belum ada Custom Dashboard",
  customDashboardEmptyDashboardSubLabel:
    "Jika kamu memiliki custom dashboard, detailnya akan muncul disini",
};

const en = {
  menuAnalytics: "Analytics",
  menuCustomDashboard: "Custom Dashboard",
  customDashboardAccessGroupNth: "Custom Dashboard {0}",
  customDashboardAutoRefreshLabel: "Auto Refresh",
  customDashboardRefreshRateinMinutes: "{0} minutes",
  customDashboardRefreshRateOff: "off",
  customDashboardEmptyDashboardLabel: "No Custom Dashboards Available",
  customDashboardEmptyDashboardSubLabel:
    "If you have a custom dashboard, the details will be shown here",
};

export default { id, en };
