const id = {
  PENDING_CONFIRMATION: "Perlu Konfirmasi",
  REJECT_CONFIRMATION: "Penolakan Diproses",
  WAITING_INVOICE: "Menunggu Invoice Final",
  WAITING_PAYMENT: "Menunggu Pembayaran",
  PAYMENT_PROCESS: "Pembayaran Diproses",
  PAID: "Lunas",
  OVERDUE: "Telat Bayar {0} Hari",
  CANCELLED: "Dibatalkan",

  SUMMARY_USAGE: "Usage Report",
  FORM_INVOICE: "Form Pengajuan Invoice",
  BAST: "Berita Acara Serah Terima",
  FINAL_INVOICE: "Invoice Final",
  billings: "Pembayaran Tagihan",
  activeBills: "Tagihan aktif",
  limitConfirmation: "Batas konfirmasi",
  limitConfirmationText: "atau otomatis digunakan untuk invoice final",
  estimatedBill: "Estimasi tagihan",
  downloadDocument: "Download dokumen",
  accept: "Setujui",
  rejetText: "Ajukan penolakan",
  dialogTitleApproved: "Setujui data usage {0}?",
  dialogContentApproved:
    "Data usage yang dikonfirmasi akan digunakan untuk invoice final.",
  dialogButtonApproved: "Ya, setujui",
  dialogTitleRejected: "Ajukan penolakan data usage?",
  dialogContentRejected:
    "Kirim detail penolakan beserta dokumen pendukung ke finance.ops@kokatto.com agar dapat diproses oleh tim Kokatto.",
  dialogButtonRejected: "Ya, tolak",
  cancel: "Batalkan",
  notes: "Keterangan",
  waitingInvoiceNote:
    "Usage sudah terkonfirmasi, mohon tunggu penerbitan <b>invoice final dalam 7 hari kerja. </b>",
  rejectionNote:
    "Agar berhasil diproses, kirim detail penolakan beserta dokumen pendukung ke <b>finance.ops@kokatto.com.</b>",
  limitPayment: "Batas pembayaran",
  billAmount: "Jumlah tagihan",
  confirmPayment: "Konfirmasi bayar",
  dialogPaymentTitle: "Cara pembayaran",
  dialogPaymentStep1: "Lakukan pembayaran dengan detail berikut:",
  paymentAmount: "Jumlah pembayaran:",
  destinationBank: "Bank tujuan:",
  accountNumber: "Nomor rekening:",
  accountName: "Nama pemilik rekening:",
  dialogPaymentStep2: "Upload bukti pembayaran di bawah ini:",
  uploadProofPayment: "Upload bukti pembayaran",
  uploadLimit: "Maksimum ukuran file 2MB",
  dialogPaymentStep3:
    "Klik tombol Konfirmasi. Tim Kokatto akan memproses verifikasi maks. 2 hari kerja.",
  confirm: "Konfirmasi",
  paymentProcessNote: "Pembayaran dalam proses verifikasi.",
  overdueNote: "agar tetap bisa menikmati layanan Kokatto",
  emptyStateTitle: "Belum ada tagihan aktif",
  emptyStateDesc:
    "Data usage dan detail tagihan akan muncul tanggal 3 tiap bulannya.",
  historyBills: "Riwayat tagihan",
  periodeBills: "Periode billing",
  status: "Status",
  paymentDate: "Tanggal pembayaran",
  document: "Dokumen",
  errorFileMessage: "File lebih besar dari 2 MB",
  uploadedFile: "File yang di upload:",
  bannerPendingConfirmContent:
    "<b>Segera cek dan konfirmasi usage {0}.</b> Data usage akan terkonfirmasi otomatis setelah {1}.",
  checkUsageData: "Cek usage",
  bannerWaitingPaymentContent:
    "<b>Lakukan pembayaran tagihan sebelum {0}</b> dan upload bukti pembayaran untuk konfirmasi.",
  banner1OverdueContent:
    "<b>Melewati batas waktu pembayaran tagihan selama {0} hari.</b> Segera bayar agar kamu tetap bisa menggunakan Kokatto.",
  banner1Overdue1WaitingConfirmContent:
    "<b>Segera konfirmasi usage {0} dan bayar {1} tagihan yang terlambat</b> agar tetap bisa menggunakan layanan Kokatto.",
  banner2OverdueLess601WaitingPaymentContent:
    "<b>Kamu punya {0} tagihan yang belum dibayar.</b> Segera lakukan pembayaran agar tetap bisa menggunakan Kokatto.",
  banner2OverdueMore601WaitingPaymentContent:
    "<b>Kamu punya {0} tagihan yang belum dibayar.</b> Agar akses Kokatto tidak diblokir, segera lunasi tagihan yang telat bayar.",
  banner3OverdueContent:
    "<b>Kamu punya {0} tagihan telat bayar</b> hingga {1} hari. Segera lakukan pembayaran agar akses Kokatto tidak ditangguhkan.",
  bannerOverdueFinancePageContent:
    "Segera bayar tagihan {0} paling lambat {1} hari lagi agar tetap bisa mengakses Kokatto.",
  bannerOverdueFinancePageTitle:
    "Akun Kokatto kamu akan ditangguhkan karena belum ada pembayaran",
  seeBilling: "Lihat tagihan",
  overlayFinanceTitle: "Pembayaran tagihan belum diterima",
  overlayFinanceContent:
    "Kamu memiliki tagihan yang belum dibayar lebih dari 90 hari. Harap lunasi tagihan untuk kembali mengakses layanan Kokatto.",
  overlayFinanceButtonText: "Lihat tagihan",
  needBastTitle: "Konfirmasi data usage {0}",
  needBastStep1:
    "Upload Bukti Acara Serah Terima yang sudah ditandatangani di bawah ini:",
  needBastStep2:
    "Klik tombol Konfirmasi untuk memberi persetujuan. Data usage yang dikonfirmasi akan digunakan untuk invoice final.",
  uploadBast: "Upload Bukti Acara Serah Terima",
};

const en = {
  PENDING_CONFIRMATION: "Need Confirmation",
  REJECT_CONFIRMATION: "Rejection in Process",
  WAITING_INVOICE: "Waiting for Final Invoice",
  WAITING_PAYMENT: "Waiting for Payment",
  PAYMENT_PROCESS: "Payment in Process",
  PAID: "PAID",
  OVERDUE: "Past Due {0} Days",
  CANCELLED: "CANCELLED",

  SUMMARY_USAGE: "Usage Report",
  FORM_INVOICE: "Invoice Submission Form",
  BAST: "Acceptance Certificate",
  FINAL_INVOICE: "Final Invoice",
  billings: "Billings",
  activeBills: "Active bills",
  limitConfirmation: "Confirmation due date",
  limitConfirmationText: "or automatically applied to the final invoice",
  estimatedBill: "Estimated bill",
  downloadDocument: "Download documents",
  accept: "Confirm",
  rejetText: "Request rejection",
  dialogTitleApproved: "Confirm {0} usage data?",
  dialogContentApproved:
    "Confirmed usage data will be used for the final invoice.",
  dialogButtonApproved: "Yes, confirm",
  cancel: "Cancel",
  dialogTitleRejected: "Reject usage data?",
  dialogContentRejected:
    "Send the rejection details and any supporting documents to finance.ops@kokatto.com so that we can process it.",
  dialogButtonRejected: "Ya, reject",
  notes: "Notes",
  waitingInvoiceNote:
    "Usage has been confirmed, please wait for final <b>invoice to be issued within 7 working days.</b>",
  rejectionNote:
    "For successful process, send the rejection detail and any supporting documents to <b>finance.ops@kokatto.com.</b>",
  limitPayment: "Payment due date",
  billAmount: "Bill amount",
  confirmPayment: "Confirm payment",
  dialogPaymentTitle: "How to pay",
  dialogPaymentStep1: "Make the payment using the following details:",
  paymentAmount: "Payment amount:",
  destinationBank: "Destination bank:",
  accountNumber: "Account number:",
  accountName: "Account name:",
  dialogPaymentStep2: "Upload the proof of payment below:",
  uploadProofPayment: "Upload proof of payment",
  uploadLimit: "Maximum file size 2MB",
  dialogPaymentStep3:
    "Click the Confirm button. Kokatto team will process the verification max. 2 working days",
  confirm: "Confirm",
  paymentProcessNote: "Payment in verification process.",
  overdueNote: "to continue using Kokatto service",
  emptyStateTitle: "No active bills yet",
  emptyStateDesc:
    "Usage data and billing details will appear on the 3rd of each month.",
  historyBills: "Billing history",
  periodeBills: "Billing period",
  status: "Status",
  paymentDate: "Payment date",
  document: "Document",
  errorFileMessage: "File larger than 2 MB",
  uploadedFile: "Uploaded File:",
  bannerPendingConfirmContent:
    "<b>Please check and confirm usage for {0} immediately.</b> Usage data will be automatically confirmed after {1}.",
  checkUsageData: "Cek usage",
  bannerWaitingPaymentContent:
    "<b>Pay the bill before {0}</b> and upload the proof of payment for confirmation.",
  banner1OverdueContent:
    "<b>Your bill is overdue by {0} days.</b> Please make a payment soon to continue using Kokatto services.",
  banner1Overdue1WaitingConfirmContent:
    "Please <b>confirm your usage for {0} and pay {1} outstanding bills</b> to continue using Kokatto services.",
  banner2OverdueLess601WaitingPaymentContent:
    "<b>You have {0} unpaid bills.</b> Please complete the payment to continue using Kokatto services.",
  banner2OverdueMore601WaitingPaymentContent:
    "<b>You have {0} unpaid bills. To avoid having your access to Kokatto blocked,</b> please settle your bills immediately.",
  banner3OverdueContent:
    "<b>{0} bills are overdue by up to {1} days.</b> Please settle the bills to prevent your access to Kokatto from being suspended.",
  bannerOverdueFinancePageContent:
    "Please pay the service bill for {0} within the next {1} days to maintain access to Kokatto.",
  bannerOverdueFinancePageTitle:
    "Your Kokatto account will be suspended due to overdue bills",
  seeBilling: "Lihat tagihan",
  overlayFinanceTitle: "No bill payment received",
  overlayFinanceContent:
    "You have an outstanding bill that is overdue by more than 90 days. Please settle the bill to regain access to Kokatto services.",
  overlayFinanceButtonText: "View bill details",
  needBastTitle: "Confirmation for {0} usage",
  needBastStep1: "Upload the signed Acceptance Certificate below:",
  needBastStep2:
    "Click the Confirm button for approval. Confirmed usage data will be used for the final invoice.",
  uploadBast: "Upload signed Acceptance Certificate",
};

export default { id, en };
