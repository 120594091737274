import { brandConstants } from "@/brand/current/PartnerConstants";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import VueI18n from "vue-i18n";
import ChatCreditIcon from "@/components/icons/ChatCreditIcon.vue";
import stylingBase from "@/brand/current/styling-base.module.scss";

import PerformanceIcon from "@/components/icons/PerformanceIcon.vue";
import SpeedIcon from "@/assets/major/ico_speed.vue";
import HeadsetIcon from "@/assets/major/ico_headset.vue";
import CommentIcon from "@/assets/minor/ico_comment_minor.vue";
import PortfolioIcon from "@/assets/major/ico_portfolio.vue";
import ContactIcon from "@/assets/major/ico_contact_book.vue";
import DownloadIcon from "@/assets/minor/ico_download_minor.vue";
import AdsIcon from "@/assets/minor/ico_ads_minor.vue";
import LightBulbIcon from "@/assets/minor/ico_bulb.vue";
import "@mdi/font/css/materialdesignicons.css";
import ViberIcon from "@/components/icons/ViberIcon.vue";
import ShopifyIcon from "@/components/icons/ShopifyIcon.vue";
import CalendarIcon from "@/components/icons/CalendarIcon.vue";
import { createVuetifyProTipTap } from "vuetify-pro-tiptap";
import "vuetify-pro-tiptap/style.css";

Vue.use(Vuetify);
Vue.use(VueI18n);

import id from "../locales/id";
import en from "../locales/en";
const lang =
  localStorage.getItem("locale") || brandConstants.DEFAULT_LANGUAGE || "id";

const i18n = new VueI18n({
  locale: lang, // set locale
  messages: {
    id,
    en,
  }, // set locale messages
});
Vue.component("BroadcastSendAddReceiverComponent", {
  methods: {
    // changeLocale() {
    //   this.$vuetify.lang.current = "id";
    // },
  },
});

const vuetify = new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    values: {
      performance: {
        // name of our custom icon
        component: PerformanceIcon, // our custom component
      },
      ico_speed: {
        component: SpeedIcon,
      },
      chatCredits: {
        component: ChatCreditIcon,
      },
      headset: {
        component: HeadsetIcon,
      },
      commentMinor: {
        component: CommentIcon,
      },
      portfolio: {
        component: PortfolioIcon,
      },
      contact: {
        component: ContactIcon,
      },
      download: {
        component: DownloadIcon,
      },
      ads: {
        component: AdsIcon,
      },
      lightBulb: {
        component: LightBulbIcon,
      },
      viber: {
        component: ViberIcon,
      },
      shopify: {
        component: ShopifyIcon,
      },
      calendar: {
        component: CalendarIcon,
      },
    },
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: {
          base: stylingBase.primaryBrandColor,
          darken4: stylingBase.primary90Color,
          darken3: stylingBase.primary80Color,
          darken2: stylingBase.primary70Color,
          darken1: stylingBase.primary60Color,
          lighten5: stylingBase.primary10Color,
          lighten4: stylingBase.primary20Color,
          lighten3: stylingBase.primary30Color,
          lighten2: stylingBase.primary40Color,
          lighten1: stylingBase.primary50Color,
        },
        warning: {
          base: stylingBase.warningBaseColor,
          darken4: stylingBase.warning90Color,
          darken3: stylingBase.warning80Color,
          darken2: stylingBase.warning70Color,
          darken1: stylingBase.warning60Color,
          lighten5: stylingBase.warning10Color,
          lighten4: stylingBase.warning20Color,
          lighten3: stylingBase.warning30Color,
          lighten2: stylingBase.warning40Color,
          lighten1: stylingBase.warning50Color,
        },
      },
    },
  },
});

const VuetifyProTipTap = createVuetifyProTipTap({
  vuetify,
  lang: "en",
});
Vue.use(VuetifyProTipTap);

export default {
  i18n,
  vuetify,
};
