import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";
import { KokattoCustomerServiceServiceClient } from "@/serviceClients/KokattoCustomerServiceServiceClient";
import { KokattoPostActivatePlayModeCustomerServiceRequest } from "@/serviceClients/requests/KokattoPostActivatePlayModeCustomerServiceRequest";
import { KokattoActivatePlayModeCustomerServiceResponse } from "@/serviceClients/responses/KokattoActivatePlayModeCustomerServiceResponse";
import { KokattoErrorResponse } from "@/serviceClients/responses/KokattoErrorResponse";
import { KokattoGetCustomerServiceResponse } from "@/serviceClients/responses/KokattoGetCustomerServiceResponse";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { State, PlayModeData, PlayModeState } from "./types";
import { KokattoGetTicketPlaylistResponse } from "@/serviceClients/responses/KokattoGetTicketPlaylistResponse";
import { KokattoGetTicketPlaylistRequest } from "@/serviceClients/requests/KokattoGetTicketPlaylistRequest";
import { KokattoTicketServiceClient } from "@/serviceClients/KokattoTicketServiceClient";
import { getUserDetailFromJwt } from "@/utils/jwtUtil";
import defineAbilityFor from "@/brand/current/ability";
import { Ability } from "@casl/ability";
import { KokattoGetCustomerServiceDetailResponse } from "@/serviceClients/responses/KokattoGetCustomerServiceDetailResponse";

const DEFAULT_ASYNC_STATE = {
  loading: false,
  data: null,
  error: null,
};

const state: State = {
  playMode: { ...DEFAULT_ASYNC_STATE },
};

const getters: GetterTree<State, any> = {
  getPlayModeState: (state: State) => state.playMode,
  getIsActivePlayMode: (state) =>
    state.playMode?.data?.isActivePlayMode || null,
  getactivePlayModeViewId: (state) =>
    state.playMode?.data?.activePlayModeViewId || null,
  getViewFeatureAbility: (state, getters, rootStaate, rootGetters) => {
    const user = getUserDetailFromJwt(rootGetters.getKokattoTokenAccess);

    const ability = new Ability(
      defineAbilityFor({
        role: rootGetters.getAccountRole,
        accountCountry: rootGetters.getAccountCountry,
        featuresAccessPermissions: user.featuresAccessPermission,
        featuresPermissions: user.featuresPermission,
      })
    );
    return ability.can("READ", "VIEW_FEATURE");
  },
};

const mutations: MutationTree<State> = {
  beforeGetPlayModeData(state: State) {
    state.playMode = { ...DEFAULT_ASYNC_STATE, loading: true };
  },
  beforeSetPlayModeData(state: State) {
    state.playMode = { ...state.playMode, loading: true, error: null };
  },
  updatePlayModeData(state: State, playModeResult: PlayModeData) {
    state.playMode = { ...DEFAULT_ASYNC_STATE, data: playModeResult };
  },
  errorGetPlayModeData(state: State, error: Error) {
    state.playMode = { ...DEFAULT_ASYNC_STATE, error };
  },
  errorGetTicketPlaylist(state: State, error: Error) {
    state.playMode = { ...state.playMode, error, loading: false };
  },
  resetPlayModeState(state: State) {
    state.playMode = DEFAULT_ASYNC_STATE;
  },
};
const actions: ActionTree<State, any> = {
  /** Update Agent Play Mode Data from Customer Service Details */
  updateAgentPlayModeData(
    context,
    response: KokattoGetCustomerServiceDetailResponse | KokattoErrorResponse
  ): void {
    const { commit } = context;

    try {
      commit("beforeGetPlayModeData");
      const { customerService } =
        response as KokattoActivatePlayModeCustomerServiceResponse;
      const data = {
        isActivePlayMode: false,
        activePlayModeViewId: "",
        activePlayModeMediaPlatform: "",
      };
      if (customerService.isActivePlayMode !== undefined)
        data.isActivePlayMode = customerService.isActivePlayMode;
      if (customerService.activePlayModeViewId !== undefined)
        data.activePlayModeViewId = customerService.activePlayModeViewId;
      if (customerService.activePlayModeMediaPlatform !== undefined)
        data.activePlayModeMediaPlatform =
          customerService.activePlayModeMediaPlatform;
      commit("updatePlayModeData", data);
    } catch (error) {
      commit("errorGetPlayModeData", error);
      console.error("[ERROR] updateAgentPlayModeData: ", error);
    }
  },

  /** Activate and Deactivate Customer Service play mode */
  async _setCsPlaymodeCustomerService(
    context,
    requestPlayMode: KokattoPostActivatePlayModeCustomerServiceRequest
  ): Promise<void> {
    const { commit, rootGetters } = context;

    try {
      commit("beforeSetPlayModeData");

      const request: KokattoPostActivatePlayModeCustomerServiceRequest = {
        ...requestPlayMode,
        email: rootGetters.getLoginEmail,
      };
      const response: KokattoGetCustomerServiceResponse | KokattoErrorResponse =
        await RefreshableRequestHelper.requestKokatto<KokattoGetCustomerServiceResponse>(
          () => {
            const kokattoCustomerServiceServiceClient =
              new KokattoCustomerServiceServiceClient();
            return kokattoCustomerServiceServiceClient.activatePlaymodeCustomerService(
              request
            );
          }
        );
      const { customerService } =
        response as KokattoActivatePlayModeCustomerServiceResponse;
      if (
        customerService.isActivePlayMode != undefined &&
        (customerService.activePlayModeViewId != undefined ||
          customerService.activePlayModeMediaPlatform != undefined)
      )
        commit("updatePlayModeData", {
          isActivePlayMode: customerService.isActivePlayMode,
          activePlayModeViewId: customerService.activePlayModeViewId,
          activePlayModeMediaPlatform:
            customerService.activePlayModeMediaPlatform,
        });
    } catch (error) {
      commit("errorGetPlayModeData", error);
      console.error("[ERROR] setActivatePlaymodeCustomerService: ", error);
    }
  },

  /** Function to Get Ticket Playlist and to skip current ticket */
  async getTicketPlaylist(
    context,
    { skipReason = "", isNext = false } = {}
  ): Promise<void> {
    const { state, commit, getters } = context;
    commit("beforeSetPlayModeData");
    try {
      let request: KokattoGetTicketPlaylistRequest;
      if (getters.getViewFeatureAbility) {
        if (!state.playMode?.data?.activePlayModeViewId) {
          throw new Error("View Id is empty");
        } else {
          request = {
            viewId: state.playMode.data.activePlayModeViewId,
          };
        }
      } else {
        if (!state.playMode?.data?.activePlayModeMediaPlatform) {
          throw new Error("Media Platform is empty");
        } else {
          request = {
            mediaPlatform: state.playMode.data.activePlayModeMediaPlatform,
          };
        }
      }

      if (skipReason) {
        request.skipReason = skipReason;
      }
      if (isNext) {
        request.isNext = true;
      }
      const response: KokattoGetTicketPlaylistResponse | KokattoErrorResponse =
        await RefreshableRequestHelper.requestKokatto<KokattoGetTicketPlaylistResponse>(
          () => {
            const kokattoTicketServiceClient = new KokattoTicketServiceClient();
            return kokattoTicketServiceClient.getAutoAssignTicketPlaylist(
              request
            );
          }
        );
      const data: PlayModeData = {
        ...state.playMode.data,
        currentTicketRoom: null,
        nextTicketId: "",
      };
      const { currentTicket, nextTicket } =
        response as KokattoGetTicketPlaylistResponse;
      if (currentTicket?.room.id) {
        data.currentTicketRoom = currentTicket.room;
      }
      if (nextTicket?.room.id) {
        data.nextTicketId = currentTicket.room.id;
      }
      commit("updatePlayModeData", data);
    } catch (error) {
      commit("errorGetTicketPlaylist", error);
      console.error("[ERROR] getTicketPlaylist: ", error);
    }
  },

  /**Activate  customer service play mode and get ticket playlist*/
  async activateCsPlayMode(
    context,
    playListParameter: string
  ): Promise<PlayModeState> {
    const { state, dispatch, getters } = context;
    const request: KokattoPostActivatePlayModeCustomerServiceRequest = {
      isActivePlayMode: true,
      email: "",
    };
    if (getters.getViewFeatureAbility) {
      const playModeViewId =
        playListParameter ?? state.playMode.data?.activePlayModeViewId ?? null;
      if (!playModeViewId) {
        const error = new Error("View Id can not be empty");
        context.commit("activateCsPlayMode()", error);
        return state.playMode;
      } else {
        request.activePlayModeViewId = playModeViewId;
      }
    } else {
      const playModeMediaPlatform =
        playListParameter ??
        state.playMode.data?.activePlayModeMediaPlatform ??
        null;
      if (!playModeMediaPlatform) {
        const error = new Error("media platform can not be empty");
        context.commit("activateCsPlayMode()", error);
        return state.playMode;
      } else {
        request.activePlayModeMediaPlatform = playModeMediaPlatform;
      }
    }
    await dispatch("_setCsPlaymodeCustomerService", request);

    if (state.playMode.data?.isActivePlayMode)
      await dispatch("getTicketPlaylist");
    return state.playMode;
  },

  /**Deactivate  customer service play mode */
  async deactivateCsPlayMode(context): Promise<void> {
    const { dispatch, getters } = context;
    const request: KokattoPostActivatePlayModeCustomerServiceRequest = {
      email: "",
      isActivePlayMode: false,
    };
    if (getters.getViewFeatureAbility) {
      request.activePlayModeViewId = "";
    } else {
      request.activePlayModeMediaPlatform = "";
    }
    await dispatch("_setCsPlaymodeCustomerService", request);
  },

  /**Check CS playmode to return assigned ticket chatroom when visit inbox-chatroom*/
  async checkCsCurrentTicket(context): Promise<void> {
    const { state, dispatch } = context;
    if (!state.playMode.data?.currentTicketRoom) {
      await dispatch("getTicketPlaylist");
    }
  },

  /**Reset Play Mode Data when user logged out */
  clearPlayModeData({ commit }) {
    commit("resetPlayModeState");
  },
  /**Reset Current Ticket Room and Next Ticket Id When CS close ticket in play mode */
  onCloseTicket(context) {
    const { state, commit } = context;
    const data = {
      ...state.playMode.data,
      currentTicketRoom: null,
      nextTicketId: "",
    };
    commit("updatePlayModeData", data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
