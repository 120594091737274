import { KokattoServiceClient } from "./KokattoServiceClient";
import { PaginationFinance } from "./responses/KokattoFinanceInvoiceResponse";
import { InvoiceModel } from "@/models/InvoiceModel";
import axios from "axios";
import { ClientFinanceModel } from "@/models/ClientFinanceModel";

export class FinanceServiceClient extends KokattoServiceClient {
  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    super(args);
    if (!this.baseUrl) {
      this.baseUrl = process.env.VUE_APP_BASEURL_FINANCE ?? "";
    }
  }

  async getInvoiceList(
    pageNumber: number,
    pageSize: number,
    statusType: string
  ): Promise<PaginationFinance<InvoiceModel>> {
    const queryParams = {
      pageNumber,
      pageSize,
    };
    const url = `${this.baseUrl}/user/invoices/${statusType}`;

    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: queryParams,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });

      return response.data.content;
    });
  }

  async postConfirmationInvoice(
    invoiceId: string,
    statusApproval: "APPROVED" | "REJECTED",
    bastUrl?: string
  ): Promise<InvoiceModel> {
    const url = `${this.baseUrl}/user/invoices/approval`;

    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(
        url,
        {
          statusApproval,
          bastUrl,
        },
        {
          headers: {
            authorization: `Bearer ${this.token}`,
          },
          params: {
            invoiceId,
          },
        }
      );

      return response.data.content;
    });
  }

  async postPaymentInvoice(
    invoiceId: string,
    urlPaymentProof: string
  ): Promise<InvoiceModel> {
    const url = `${this.baseUrl}/user/invoices/payment`;

    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(
        url,
        {
          urlPaymentProof,
        },
        {
          headers: {
            authorization: `Bearer ${this.token}`,
          },
          params: {
            invoiceId,
          },
        }
      );

      return response.data.content;
    });
  }

  async getPresignedPaymentUrl(
    mimeType: string,
    type: string,
    filename: string
  ): Promise<string> {
    const queryParams = {
      mimeType,
      type,
      filename,
    };

    const url = `${this.baseUrl}/user/file`;

    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: queryParams,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });

      return response.data.content.presignUrl;
    });
  }

  async getDetailClientFinance(): Promise<ClientFinanceModel> {
    const url = `${this.baseUrl}/user/client/me`;

    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });

      return response.data.content;
    });
  }
}
