export enum InvoiceStatusType {
  MANUAL_RELEASE = "MANUAL_RELEASE",
  PENDING_CONFIRMATION = "PENDING_CONFIRMATION",
  REJECT_CONFIRMATION = "REJECT_CONFIRMATION",
  WAITING_INVOICE = "WAITING_INVOICE",
  WAITING_PAYMENT = "WAITING_PAYMENT",
  PAYMENT_PROCESS = "PAYMENT_PROCESS",
  PAID = "PAID",
  OVERDUE = "OVERDUE",
  CANCELLED = "CANCELLED",
}

export enum DocumentInvoiceType {
  SUMMARY_USAGE = "SUMMARY_USAGE",
  FORM_INVOICE = "FORM_INVOICE",
  BAST = "BAST",
  FINAL_INVOICE = "FINAL_INVOICE",
  PAYMENT_PROOF_IMAGE = "PAYMENT_PROOF_IMAGE",
}

export enum ConfirmationState {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}
